@import '../../../styles/variables';

.form-title {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
  color: #6c757d;
  margin: 0;
}

.file-uploader {
  width: 100%;
  height: 100px;
  border: 2px dashed $gray-400;
  background-color: $gray-100;
}

.file-uploader__message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;

  .message__main {
    color: $gray-700;
    font-size: 1rem;
    margin: 0;
  }
}
