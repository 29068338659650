@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.form-section__header {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0;
  }
}

.form__fieldset {
  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  &__description {
    color: $gray-600;
    line-height: 1.2;
  }
}

.form-otp {
  max-width: 400px;
}
